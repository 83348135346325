import countries from 'i18n-iso-countries';
import dutchCountryTranslations from 'i18n-iso-countries/langs/nl.json';

import {
  Quarter,
  ResourceType,
  RequirementStatus,
  ActionStatus,
} from './schema';
import { State } from './types';

countries.registerLocale(dutchCountryTranslations);

export const STATUS_OK = 'bg-green text-green border-green';
export const STATUS_ERROR = 'bg-red text-red border-red';
export const STATUS_WARNING = 'bg-orange text-orange border-orange';
export const STATUS_UNDEFINED = 'bg-gray text-gray border-gray';

export const LAWS_STATUS_OK = 'bg-green text-white border-green';
export const LAWS_STATUS_ERROR = 'bg-red text-white border-red';
export const LAWS_STATUS_WARNING = 'bg-orange text-white border-orange';
export const LAWS_STATUS_UNDEFINED = 'bg-gray text-white border-gray';

export const REQUIREMENT_STATUS_STATE_MAP = {
  YES: State.Success,
  NO: State.Danger,
  CONFORM: State.Success,
  IMPROVEMENT: State.Warning,
  LARGE_NON_CONFORMITY: State.Danger,
  SMALL_NON_CONFORMITY: State.Danger,
  DOES_NOT_APPLY: State.Default,
  INFORMATIVE: State.Info,
  TO_BE_DECIDED: State.Tbd,
};

export const AUDIT_RAPPORT_STATUS_STATE_MAP = {
  INITIATED: State.Default,
  GENERATING: State.Warning,
  COMPLETED: State.Success,
  FAILED: State.Danger,
};

export const QUARTERS = [
  { value: Quarter.Q1, label: 'Q1' },
  { value: Quarter.Q2, label: 'Q2' },
  { value: Quarter.Q3, label: 'Q3' },
  { value: Quarter.Q4, label: 'Q4' },
];

export const THEME_SLUGS = {
  ENVIRONMENT_ENERGY_FLANDERS: 'environment-and-energy-flanders',
  ENVIRONMENT_ENERGY_FEDERAL: 'environment-and-energy-federal',
  WELFARE: 'welfare',
};

export const ROLE_SLUGS = {
  ROOT: 'root',
  TENANT_ADMINISTRATOR: 'tenant-administrator',
  TENANT_USER: 'tenant-user',
  COMPANY_ADMINISTRATOR: 'company-administrator',
  COMPANY_USER: 'company-user',
  COMPANY_READ_ONLY: 'company-read-only',
  CONTENT_ADMINISTRATOR: 'content-administrator',
  READ_ONLY: 'read-only',
};

export const ROLE_ACCESS_ROLE_MAP = {
  [ROLE_SLUGS.ROOT]: Object.values(ROLE_SLUGS),
  [ROLE_SLUGS.TENANT_ADMINISTRATOR]: [
    ROLE_SLUGS.TENANT_ADMINISTRATOR,
    ROLE_SLUGS.TENANT_USER,
    ROLE_SLUGS.COMPANY_ADMINISTRATOR,
    ROLE_SLUGS.COMPANY_USER,
    ROLE_SLUGS.COMPANY_READ_ONLY,
    ROLE_SLUGS.CONTENT_ADMINISTRATOR,
    ROLE_SLUGS.READ_ONLY,
  ],
  [ROLE_SLUGS.COMPANY_ADMINISTRATOR]: [
    ROLE_SLUGS.COMPANY_USER,
    ROLE_SLUGS.COMPANY_READ_ONLY,
  ],
};
export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  AUDITS: '/audits',
  CREATE_AUDIT: '/audits/create',
  AUDIT: `/audits/[id]`,
  AUDIT_REQUIREMENTS: `/audits/[id]/[requirementId]`,
  AUDIT_RAPPORTS: `/audits/[id]/rapports`,
  ACTIONS: '/actions',
  REMARKS: '/remarks',
  USERS: '/users',
  TENANT_USERS: '/tenant-users',
  USERS_DETAIL: `/users/[id]`,
  SITES: '/sites',
  SITE: `/sites/[id]`,
  COMPANIES: '/companies',
  LAWS: `/laws`,
  LAWS_EDIT: `/laws/[id]`,
};

export const RESOURCE_TYPE_ICON = {
  [ResourceType.Law]: 'ScaleIcon',
  [ResourceType.Article]: 'DocumentIcon',
  [ResourceType.PseudoArticle]: 'DocumentDuplicateIcon',
  [ResourceType.Attachment]: 'PaperClipIcon',
  [ResourceType.Book]: 'BookmarkIcon',
  [ResourceType.Chapter]: 'BookmarkIcon',
  [ResourceType.Department]: 'BookmarkIcon',
  [ResourceType.SubDepartment]: 'BookmarkIcon',
  [ResourceType.Inscription]: 'BookmarkIcon',
  [ResourceType.Title]: 'BookmarkIcon',
  [ResourceType.Note]: 'PencilIcon',
  [ResourceType.Annotation]: 'AnnotationIcon',
  [ResourceType.AnnotationGroup]: 'CollectionIcon',
};

export const STATUS_COLOR = {
  [RequirementStatus.Conform]: '#4cd47a',
  [RequirementStatus.DoesNotApply]: '#d1d1d6',
  [RequirementStatus.Improvement]: '#f5b243',
  [RequirementStatus.LargeNonConformity]: '#f3685b',
  [RequirementStatus.No]: '#f3685b',
  [RequirementStatus.SmallNonConformity]: '#f3685b',
  [RequirementStatus.ToBeDecided]: '#f5cd41',
  [RequirementStatus.Informative]: '#66b0ff',
  [RequirementStatus.Yes]: '#4cd47a',
};

export const ACTION_COLOR = {
  [ActionStatus.Cancelled]: '#c4c4c4',
  [ActionStatus.Completed]: '#4cd47a',
  [ActionStatus.Open]: '#f3685b',
  [ActionStatus.ToStart]: '#f5b243',
};

export const phoneNumberRegex = /^\d{1,4}\d{9}$/;

export const DUTCH_COUNTRIES_BY_ISO_CODE = countries.getNames('nl', {
  select: 'official',
});

export const ADMINISTATIVE_CONTACT_EMAIL = 'koen.vanhamme@innigroup.com';
export const DEMO_MAIL_SUBJECT = 'INNIsure: demo';
export const DEMO_MAIL_BODY =
  'Beste Koen,\n\nik had graag een demo aangevraagd voor INNIsure, neemt u contact met me op om eentje in te plannen?';
